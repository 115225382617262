<template>
    <v-navigation-drawer
        app
        v-model="drawer"
        clipped
        :mini-variant.sync="mini"
        permanent
        v-bind:class="{ active: isActive }"
    >
        <v-list nav class="pa-0">
            <v-list-item dark color="primary" v-for="item in items" :key="item.title" link white :to=item.link v-if="canViewNavItem(item)">
                <v-list-item-icon>
                    <v-icon color="white">{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>

        <v-list-item class="px-2 chevron-drawer justify-end">
            <v-btn icon @click.stop="mini = !mini">
                <v-icon large color="white">{{ drawerIcon }}</v-icon>
            </v-btn>
        </v-list-item>
    </v-navigation-drawer>
</template>

<script>
import { mapState } from 'vuex'

export default {
    data () {
        return {
            drawer: true,
            isActive: false,
            mini: true,
        }
    },
    computed: {
        drawerIcon() {
            return this.mini ? "mdi-chevron-right" : "mdi-chevron-left";
        },
        ...mapState({
            items : state => state.navItems,
        })
    },
    methods: {
        canViewNavItem (navItem) {
            if(typeof navItem.permission !== 'undefined') {
                return this.can(navItem.permission);
            }
            return true
        },
    },
}
</script>

<style scoped>
    .v-list-item__title:not(.account) {
        color: #FFFFFF;
    }

    .v-list-item--link:before {
        background-color: #FFFFFF;
        border-radius: 0px;
    }

    .v-navigation-drawer {
        background:  url("/images/qf-connector-background.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-color: #3c3c3c;
        background-blend-mode: soft-light;

    }
</style>
