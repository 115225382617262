import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist'
import auth from './modules/auth';
import ACTION_TYPES from "./action-types";
import MUTATION_TYPES from "./mutation-types";
import { v4 as uuidv4 } from 'uuid';

Vue.use(Vuex);

export default new Vuex.Store({
    plugins: [
        new VuexPersistence({
            reducer: rootState => ({
                auth: rootState.auth,
            }),
        }).plugin,
    ],
    modules: {
        auth,
    },
    state: {
        alerts: [],
        navItems: [
            { title: 'Fabric Parent Products', icon: 'mdi-flag', link: {name: 'dashboard.products.index', query: {level: 1, product_type: "Fabric"}}, permission: 'PRODUCT_READ_WEB' },
            { title: 'Carpet Parent Products', icon: 'mdi-flag', link: {name: 'dashboard.products.index', query: {level: 1, product_type: "Carpet"}}, permission: 'PRODUCT_READ_WEB' },
            { title: 'Binding Parent Products', icon: 'mdi-flag', link: {name: 'dashboard.products.index', query: {level: 1, product_type: "Binding"}}, permission: 'PRODUCT_READ_WEB' },
            { title: 'Child Products', icon: 'mdi-flag', link: {name: 'dashboard.products.index', query: {level: 2}}, permission: 'PRODUCT_READ_WEB' },
        ],
    },
    mutations: {
        [MUTATION_TYPES.ADD_ALERT](state, value) {
            state.alerts.push(value);
        },
        [MUTATION_TYPES.REMOVE_ALERT](state, alertUuid) {
            state.alerts = state.alerts.filter((alert) => {
                return alert.uuid !== alertUuid;
            });
        },
    },
    actions: {
        [ACTION_TYPES.ADD_ALERT.NAME]({ commit }, alertData) {
            const newAlert = Object.assign({
                uuid: uuidv4(),
                visible: true,
                timeout: 2000,
            }, alertData);
            commit(MUTATION_TYPES.ADD_ALERT, newAlert);
            setTimeout(() => {
                commit(MUTATION_TYPES.REMOVE_ALERT, newAlert.uuid);
            }, newAlert.timeout + 500)
        },
    },
});
